<template>
  <div class="bg-white pb-2 mb-4">
    <div class="bg-orange guest-form-header">
      <div class="text-title --c-white text-center font-weight-bold pt-3 pb-3">
        東急バケーションズポイント利用
      </div>
    </div>
    <div class="px-4 my-4 text-title">
      <div>宿泊料合計： {{ totalAccommodationFee | toThousands }}円</div>
      <div>所有ポイント数： {{ currentPoint | toThousands }}ポイント</div>
      <div>
        <v-checkbox label="東急バケーションズポイントを利用する" v-model="useTvp" :disabled="currentPoint <= 0"/>
      </div>
      <template v-if="useTvp">
        <div v-for="(accommodationFee, ind) in accommodationFeePerBooking" :key="ind" class="mb-2">
          <div>予約{{ ind + 1 }} ({{ accommodationFee | toThousands }}円)</div>
          <div class="d-flex align-center">
            <div>ポイント利用：</div>
            <div style="width: 100px;">
              <v-text-field
                type="number"
                dense
                flat
                hide-details="auto"
                :rules="[$rules.required, $rules.maxInt(accommodationFee, {
                  message: '利用ポイント数が宿泊料金を超えています。'
                }), $rules.minInt(0), totalTvpLTECurrentTvpRule]"
                :value="value[ind] || 0"
                @input="updateValue(ind, $event)"
                :disabled="currentPoint <= 0"
              />
            </div>
            <div>ポイント</div>
          </div>
        </div>
        <div class="--c-dark-red">
          ご宿泊開始日の前日まで変更することができます。
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PointBalance',
  props: {
    accommodationFeePerBooking: Array,
    currentPoint: Number,
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      useTvp: false
    }
  },
  computed: {
    totalAccommodationFee () {
      return this.accommodationFeePerBooking.reduce((acc, cur) => acc + cur, 0)
    },
    totalUseTvp () {
      return this.value.reduce((acc, cur) => acc + parseInt(cur, 10), 0)
    },
    totalTvpLTECurrentTvpRule () {
      return () => {
        if (this.totalUseTvp <= this.currentPoint) {
          return true
        }
        return '利用ポイント数が所有ポイントを超えています。'
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue) {
        // make sure value has the same length as accommodationFeePerBooking
        if (newValue.length !== this.accommodationFeePerBooking.length) {
          const padded = [...newValue, ...this.accommodationFeePerBooking.map(() => 0)]
            .slice(0, this.accommodationFeePerBooking.length)
          this.$emit('input', padded)
        }
      }
    },
    useTvp (v) {
      if (!v) {
        this.$emit('input', this.value.map(() => 0))
      }
    }
  },
  methods: {
    updateValue (ind, tvp) {
      const newValue = [...this.value]
      newValue[ind] = parseInt(tvp, 10)
      this.$emit('input', newValue)
    }
  }
}
</script>
